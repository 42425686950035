.dock-container {
    text-align: left;
    height: 41px;
    width: 100%;
    background-color: #454545;
    color: #fff;
    border-top:1px solid #333333;
    position: relative;
    display: flex;
}

.App-clock{
  line-height: 40px;
  text-align: right;
  width: 50%;
  padding-right: 20px;
}

.dock-container a{
  line-height: 40px;
  
  padding-left: 20px;
  text-align: left;
  display:inline-block;

}

.dock-container div{
  width: 50%;
}



@media only screen and (min-width: 800px) {

  .App-clock{
    padding-right: 70px;
  }

  .dock-container a{
    padding-left: 70px;
  }
}