#root,
.app {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	/*height: 100vh;*/
}

body {
	background-color: #DEDEDE;
	background-image: url("https://cdn.huntakiller.com/huntakiller/s6/logo.png");
	background-position: center;
	background-repeat: no-repeat;
	overflow: hidden;

    
}

.deviant-container, .menu{
	font-family: 'Roboto Slab', serif;
	font-weight: 700;
}

.text-xs {
    font-weight: 400;
}

.ps__rail-y, .ps__rail-x{
    z-index: 5;
}


@media only screen and (max-width: 800px) {
    .modal.react-draggable {
        touch-action: auto !important;
        }

}

a,
img,
h1,
p,
h2,
button {
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Old versions of Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
}

.flex-1 {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.relative {
	position: relative;
}

.icon-wrapper {
	position: absolute;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	width: 70px;
	/*height: 104px;*/
	cursor: pointer;
}

.icon-wrapper.selected .icon-image {
	border: 1px solid #dae1e7;
}

.icon-wrapper.selected .icon-name span {
	white-space: pre-wrap;
	background-color: #2779bd;
	color: #fff;
}

.icon-name {
	color: #22292f;
	font-size: .875rem;
	text-align: center;
	pointer-events: none;
	margin-top: .25rem;
}

.icon-name span {
	padding-left: .25rem;
	padding-right: .25rem;
}

.icon-wrapper .icon-image {
	border: 1px solid transparent;
	/*width: 64px;
    height: 64px;*/
	background-size: 100%;
	background-repeat: no-repeat;
	pointer-events: none;
}

.z-10 {
	z-index: 10;
}

.w-full {
	width: 100%;
}

.h-full {
	height: 100%;
}

.seasonDesktop {
	background-color: #DFDDDE;
}

.login-box {
	background-color: #454545;
	width: 250px;
	max-width: 90%;
	position: relative;
}


.login-box-editor{
	background-color: #454545;
    width: 400px;
    max-width: 90%;
    position: relative;
    margin: 0 auto;
    margin-top: 10%;
    text-align: center;
}

.login-box-editor h1{
	font-weight: 400;
}


.universityportal .login-box{
	background-color: #273d92;
	color: #fff;
}

.login-box .close-icon-modal{
	    position: absolute;
    top: 5px;
    right: 5px;
    width: 20px;
}

.header .close-icon {
	width: 20px;
}

.menu-icon-dt{
	width: 26px;
}
.login-box input:focus {
	outline: none;
	box-shadow: none;
}

.login-box h1,
.login-box a {
	color: #fff;
}

.login-box a {
	text-decoration: underline;
}

.login-box img {
	max-width: 70%;
}

.error {
	text-align: center;
	font-weight: bold;
	font-size: 12px;
	color: #E7B3BB;
}

.cadence {
	background-color: #C1B3D8;
	background-image: url("https://cdn.huntakiller.com/huntakiller/s6/graylogo.svg");
	background-position: center;
	background-repeat: no-repeat;
}

.season5 {
	background-color: #798a79;
	background-image: url("https://cdn.huntakiller.com/huntakiller/s6/graylogo.svg");
	background-position: center;
	background-repeat: no-repeat;
}

.bg-grey {
	background-color: #DEDEDE;
}

.bg-grey:hover {
	background-color: #DEDEEE;
}

.modal {
	position: absolute;
	width: 100%;
	/*height: 100vh;*/
	background-color: #fff;
	left: 0;
	top: 0;
	max-width: 100%;
	/*min-height: 100vh;*/
}

.modalDiv {
	width: 100%;
	height: 100%;
}

.modalcontainer {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.modalcontainer .header {
	background-color: #B3B3B3;
	text-align: center;
	height: 50px;
	line-height: 50px;
	font-weight: bold;
	position: relative;
}

.modalcontainer .close {
	height: 50px;
	width: 50px;
	position: absolute;
	background-image: url("https://cdn.huntakiller.com/huntakiller/s6/closebutton.svg");
	background-position: center;
	background-size: 100%;
	right: 0px;
	top: 0px;
}

.ScrollbarsCustom {
	width: 100%;
	height: 100%;
	display: block;
	flex: 1 1;
}

.imageViewer {
	position: relative;
	width: 100%;
	height: 100%;
}

.bottom-imageViewer-controllers {
	display: none;
}

.content-file {
	max-width: 100%;
	max-height: 100%;
}

.editor-container .content-file {
	position: relative;
}

.editor-container .content-file img{
	position: absolute;
	display: block;
	top: 0;
	
}

.editor-container .content-file img:first-child{
	position: relative;

}

.rtfdoc {
	padding: 20px;
	font-size: 12px;
	width: calc(100%);
}

object {
	max-width: 100%;
}

.rtfdoc h2,
.rtfdoc p,
.rtfdoc object {
	margin-bottom: 15px;
}

.rtfdoc h2 {
	font-weight: bold;
	font-size: 14px;
}

.rtfdoc ul {
	list-style: disc;
	padding-left: 15px;
	margin-bottom: 15px;
}

.rtfdoc ul li {
	margin-top: 10px;
	margin-bottom: 10px;
}

.rtfdoc a {
	color: blue;
	text-decoration: underline;
}

.rtfdoc table {
	min-width: 120%;
	width: 100%;
}

.rtfdoc table th,
.rtfdoc table td {
	padding: 10px;
}

.rtfdoc table,
.rtfdoc th,
.rtfdoc td {
	border: 1px solid black;
}

.rtfdoc table tr:nth-child(even) {
	background-color: #eee;
}

.rtfdoc table tr:nth-child(odd) {
	background-color: #fff;
}

.strikethrough {
	text-decoration: line-through;
}

.overlaypw,
.devianttracker {
	background: rgba(0, 0, 0, 0.2);
	position: fixed;
	left: 0;
	top: 0;
	z-index: 10000000000;
}

.devianttracker {
	background-color: #333337;
	color: #fff;
}

.devianttracker .header {
	background-image: -webkit-gradient(linear, left top, left bottom, from(#333337), to(#26262a));
	background-image: linear-gradient(180deg, #333337, #26262a);
	height: 48px;
	width: 100%;
}

.devianttracker .header .logo {
	width: 175px;
}

.login-dt-folder {
	position: fixed;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.5);
}

.login-dt {
	position: absolute;
	background-color: rgba(255, 255, 255, 0.5);
}

.login-dt .login-box {
	width: 400px;
}

.login-dt .login-box h2 {
	margin-top: 1rem;
	font-size: 14px;
	text-align: center;
}

.deviant-container {
	padding: 1rem;
	overflow: auto;
}

.deviant-container h2 {
	font-size: 24px;
	margin-bottom: 2rem;
}

.deviant-container p {
	margin-bottom: 2rem;
	font-size: 14px;
	max-width: 600px;
}

.text-area-container>div {
	width: 100%;
	text-align: center;
}

.text-area-container textarea {
	width: 100%;
	color: #333337;
	min-height: 200px;
	resize: none;
	padding: 10px;
}

.text-area-container textarea:focus {
	outline: none;
}

.header-shift-decoder>div {
	align-self: center;
	font-weight: 700;
	width: 100%;
	text-align: center;
	height: 50px;
	line-height: 50px;
	background-color: hsla(0, 0%, 100%, .2);
}

.header-shift-decoder,
.text-area-container {
	width: 100%;
	max-width: 1000px;
}

.header-shift-decoder>div:first-child {
	border: none;
}

.text-area-container>div:first-child {
	border: none;
}

.cta-contaniner {
	max-width: 1000px;
	width: 100%;
}

.cta-contaniner>div {
	float: right;
	align-items: center;
}

.video-container .cta-contaniner>div {
	float: none;
}

.video-container .cta-contaniner>div button {
	margin: 0 auto;
}

.cta-contaniner button {
	background-color: #eb4851;
	color: #fff;
}

.cta-contaniner button:hover {
	opacity: 0.9;
	background-color: #eb4851;
}

.cta-contaniner input {
	height: 40px;
	color: #000;
	line-height: 40px;
	padding: 0 0 0 15px;
	width: 45px;
}

.manual-decoder-content input {
	color: #000;
	width: 100%;
	padding: 10px;
}

.manual-decoder-content {
	max-width: 100%;
	width: 800px;
	margin: 0 auto;
	margin-top: 2rem;
}

.alphabet-container {
	margin: 0 auto;
	width: 630px;
	max-width: 100%;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-top: 2rem;
	margin-bottom: 2rem;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
}

.alphabet-container .cipher-letter:last-child {
	display: none;
}

.cipher-letter {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-transform: uppercase;
	font-weight: 700;
	width: 3rem;
	margin-bottom: 1rem;
}

.cipher-letter span {
	opacity: .5;
	margin-left: .25rem;
}

.cipher-letter input {
	font-size: 12px;
	font-weight: 700;
	width: 25px;
	text-align: center;
	text-transform: uppercase;
	color: #000;
	padding: 0;
}

.manual-decoder-content input:focus {
	outline: none;
}

.cipher-char {
	width: 40px;
	height: 40px;
	font-size: 30px;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-transform: uppercase;
	font-weight: 700;
	margin: .25rem;
	padding: .5rem;
	line-height: 1;
}

.decryptedText-container {
	min-height: 80px;
}

.database-container-content {
	max-width: 800px;
	margin: 0 ;
}

.cheeseburger-menu-inner {
	background-color: #1f1f23;
	color: #fff;
}

.deviant-container h4 {
	text-transform: uppercase;
	font-size: 11px;
	opacity: 0.5
}

.deviant-container h2 {
	text-transform: uppercase;
	font-size: 25px;
}

.player-item2{
	display: none;
}


.sliders, .audio-controls{
	width: 300px;
	margin: 0 auto;
	max-width: 95%;
}

.audio-controls{
	margin-top: 5rem;
	margin-bottom: 4rem;
}
/*.audio-controls button{
	margin-left: 15px;
    margin-right: 15px;
}*/
.download-transcript-btn{
	width: 120px;
    height: 25px;
    margin-top: 7.5px;
    background-color: #FEFEFE;
    color: #000;
    border-radius: 10px;
    margin-left: 15px;
    margin-right: 15px;
    text-align: center;
    display: inline-block;
    box-shadow: inset 0 0 2px #333;
    font-weight: 400;
}

.videotranscriptcontainer{
	text-align: center;
    margin-top: 2rem;
}

.rangeslider-horizontal{
	height: 8px;
}

.rangeslider-horizontal .rangeslider__fill{
	background-color: rgb(235, 72, 81);
}

.rangeslider-horizontal .rangeslider__handle{
	width: 20px;
    height: 20px;

}

.rangeslider-horizontal .rangeslider__handle:after{
	width: 10px;
    height: 10px;
    top: 4px;
    left: 4px;
}
.waveform{
	width: 100%;
	
	margin: 5rem auto 1rem auto;
}

.player-item {
	margin-bottom: 30px;
	max-width: 1000px;
	margin:0 auto;
}

.player-item h3 {
	text-align: center;
	margin-bottom: 10px;
}

.react-audio-player {
	width: 100%;
	max-width: 500px;
	margin: 0 auto;
}

.react-audio-player:focus {
	outline: 0;
}

.menu {
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.menu-item {
	cursor: pointer;
	padding: 1rem;
}

.menu-item.current {
	color: #e84851;
	border-color: #e84851;
	border-bottom-width: 2px;
}

.menu-item:hover {
	color: #e84851;
}

.menu-item.menu-item-disabled {
	cursor: default;
	color: #fff;
	opacity: 0.5;
}

.menu-item.menu-item-disabled:hover{
	color: #fff;
}
.database-results .flex {
	display: block;
	margin-bottom: 1.5rem;
}

.database-input,
.database-results {
	background-color: hsla(0, 0%, 100%, .2);
	width: 100%;
	margin-top: 1rem;
	color: #fff;
	opacity: .5;
	/*font-weight: 700;*/
}

.database-input {
	height: 50px;
	font-size: 20px;
	padding-left: 1rem;
}

.database-input:focus {
	border: none;
	outline: none;
}

.database-icon {
	height: 30px;
	width: 30px;
	top: 10px;
	right: 10px;
	position: absolute;
	margin-top: 1rem;
}

.database-results {
	font-size: 20px;
	padding: 2rem;
	opacity: 0.8;
}

.mobile {
	display: block;
}

.mobile.flex{
	display: flex;
}

.desktop, .desktop.flex {
	display: none;
}

.gallery-viewer__back {
	right: 0;
	top: -67px;
	width: 90px;
	height: 27px;
	border-radius: 4px;
	border: 1px solid hsla(0, 0%, 100%, .5);
	cursor: pointer;
	position: absolute;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	opacity: .5;
}

.gallery-viewer__back-arrow {
	margin-top: -2px;
	font-size: 16px;
	color: #fff;
	padding-right: 1rem;
}

.gallery-viewer__back-text {
	font-size: 10px;
	font-weight: 700;
	color: #fff;
}

.gallery-viewer__back:hover {
	opacity: 1;
}

.planofaction ul {
    list-style-type: none;
    padding-left: 0;
}
.planofaction ul ul {
    padding-left: 15px;
}

.evidencechecklist ul {
    list-style-type: none;
}

input[type=checkbox]:checked + b {
    text-decoration: line-through;
  }

 .notes-section{
 	margin-bottom: 2rem;
    padding-left: .5rem;
    padding-right: .5rem;
    font-weight: 400;
    font-size: .875rem;
    background-color: #505054;
 }

 .transcript-section {
    margin-bottom: 2rem;
    padding-left: .5rem;
    padding-right: .5rem;
    font-weight: 400;
    font-size: .875rem;
    background-color: #505054;
}

.header.bg-blue {
    background-image: linear-gradient(180deg, #273d92, #273d92);
    color: #fff;
}
.devianttracker.universityportal{
	background-color: #fff;
	color: #000;
}

.devianttracker.universityportal .header{
	height: auto;
}

.devianttracker.universityportal .header .flex{
	height: 40px;
}

.universityportal .user-infos{
	font-size: 12px;
	margin-bottom: 10px;
	margin-top: 10px;
}

.universityportal .user-infos p {
	display: inline-block;
	

}
.headerImageViewUniveristy .gallery-viewer__back{
	top: 0;
    border-color: #273d92;
    opacity: 1;
}

.headerImageViewUniveristy .gallery-viewer__back:hover{
	opacity: 0.5;
}

.headerImageViewUniveristy .gallery-viewer__back-arrow, .headerImageViewUniveristy .gallery-viewer__back-text{
	color: #273d92;
}

.classSchedule {
	max-width: 800px;
	text-align: center;
	width:100%;
	
}


.classSchedule thead th{
	background-color: #eee;
    border-width: 1px;
    border-color: #4a4a50;
    padding-left: .5rem;
    padding-right: .5rem;
    padding-bottom: .5rem;
    padding-top: 2rem;
    text-align: center;
    font-size: .875rem;

}

.classSchedule tbody td{
	border-width: 1px;
    border-color: #4a4a50;
    font-size: .875rem;
    padding-left: .5rem;
    padding-right: .5rem;
    padding-bottom: .5rem;
    padding-top: .5rem;
}

.universityportal .deviant-container h2{
	color: #273d92; 
	font-weight: 700;
	font-size: 18px;
}

.universityportal .deviant-container h3{
	color: #273d92; 
	font-weight: 700;
}

.universityportal .deviant-container p{
	max-width: 620px;
}



.universityportal .deviant-container{
	font-family: unset;
}


.menu-universityportal .cheeseburger-menu-inner{
	background-color: #fff;
    color: #000;
}

.menu-universityportal .menu{
	padding-top: 0rem;
	padding-bottom: 0;
	font-family: unset;
}

.menu-universityportal .menu .menu-item{
	padding:0 1rem;
}

.text-blue {
    color: #273d92;
}

.menu-item.text-blue:hover {
	color: #273d92;
	opacity: 0.8;
}

.image-gallery .galleryButton{
	position: absolute;
    top: calc(50% - 10px);
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}


.image-gallery .prevBtn{
	left:1rem;
	border-right: 20px solid #e84851;
}


.image-gallery .nextBtn{
	right: 1rem;
	border-left: 20px solid #e84851;
    
}



.sampleContainer {
	margin-top: 5rem;
}

.sampleContainer > div.relative{
	margin-bottom: 2rem;
}


#email-icon {
    bottom: 50px;
    right: 11px;
}

.inbox {
    background: #fff;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 10000000000;

}

.HAKEmailChatContainer {
    overflow-y: scroll;
}

.overlay {
    position: fixed;
    top: 50px;
    left: 0;
    background: rgba(255,255,255,.8);
}



button:focus {outline:0;}
/*.menu-universityportal{
	max-width: 100% !important;
}*/

@media only screen and (max-width: 400px) {
	.rtfdoc.lyrics {
		font-size: 10px;
	}
}

@media only screen and (min-width: 800px) {
	.universityportal .user-infos{ 
		margin-top: 0;
	}
	.sampleContainer > div.relative{
		min-height: 50vh;
		
	}
	.image-gallery .galleryButton:hover{
		opacity: 0.9;
	}

	.desktop {
		display: block;
	}

	.desktop.flex{
		display: flex;
	}
	.mobile, .mobile.flex {
		display: none;
	}

	.menu-item{
		display: inline-block;
		padding: 0;
		margin: 0.6rem 1rem; 

	}
	.text-area-container>div,
	.header-shift-decoder>div {
		width: 50%;
	}
	.header-shift-decoder>div:first-child {
		/*border-right: 1px solid #fff;*/
		margin-right: 10px;
	}
	.text-area-container>div:first-child {
		/*border-right:1px solid #333337;*/
		margin-right: 10px;
	}
	.icon-wrapper {
		width: 80px;
	}
	.rtfdoc table {
		min-width: 100%;
	}
	/*.rtfdoc {
		width: 110%;
		padding-right: calc(20px + 10%);
	}*/
	.rtfdoc ul {
		padding-left: 15px;
		margin-left: 40px;
	}
	object {
		width: auto;
	}
	.bottom-imageViewer-controllers {
		display: block;
		position: absolute;
		bottom: 10px;
		left: 0;
		width: calc( 100% - 11px);
		height: 40px;
		background-color: #B3B3B3;
		align-items: center;
		padding-left: 15px;
		padding-right: 15px;
	}


	.universityportal .images-container .bottom-imageViewer-controllers{
		background-color: #eee;
	}
	.images-container .bottom-imageViewer-controllers, .sampleContainer .bottom-imageViewer-controllers, .editor-container  .bottom-imageViewer-controllers{
		position: relative;
		bottom: 0;
		width: 100%;
		background-color: hsla(0, 0%, 100%, .2);
	}
	.bottom-imageViewer-controllers button,
	.bottom-imageViewer-controllers a {
		width: 100px;
		height: 25px;
		margin-top: 7.5px;
		background-color: #FEFEFE;
		color: #000;
		border-radius: 10px;
		margin-left: 15px;
		margin-right: 15px;
		text-align: center;
		display: inline-block;
		-moz-box-shadow: inset 0 0 2px #333;
		-webkit-box-shadow: inset 0 0 2px #333;
		box-shadow: inset 0 0 2px #333;
	}
	.bottom-imageViewer-controllers a {
		float: right;
		font-size: 0.6rem;
		line-height: 25px;
	}
	.bottom-imageViewer-controllers button:focus {
		border: none;
		outline: none;
	}
	.App-clock {
		padding-right: 70px;
	}
	.dock-container a {
		padding-left: 70px;
	}
	.modal {
		width: 20px;
		height: 25px;
		width: 800px;
		height: 525px;
		min-height: auto;
	}
	.folder-content {
		min-height: 600px;
	}
	.content-file {
		max-width: none;
		max-height: none;
		/*min-width: 110%;*/
	}
	.imageViewer .content-file {
		margin-bottom: 40px;
		min-width: 100%;
	}


	.editor-container .imageViewer .content-file {
		
		min-width: auto;
	}
	.imageViewer img {
		width: 100%;
	}
}